
































































































































































































































































































































































































































































































































































































































































































































.ground {
  height: 0.3rem;
  width: 100%;
  border-radius: 1rem;
}
.man-walk-container {
  width: calc(100% - 56px);
  .man-walk {
    transition: 0.2s;
    left: 0%;
    position: relative;
    display: inline-block;
  }
}
.flag {
  right: 0;
  bottom: 5px;
  &.won {
    transform: translateY(-23px) scale(0.75);
  }
}
